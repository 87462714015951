import Vue from 'vue'
import App from './App.vue'
import { Button } from 'vant';
import { 
  Cell, 
  CellGroup, 
  Form, 
  Field, 
  Radio, 
  RadioGroup,
  Popup,
} from 'vant';

import '@/assets/styles/index.scss'
import 'vant/lib/index.css';

Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
