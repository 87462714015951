import axios from 'axios'
import { Toast } from 'vant'

axios.defaults.crossDomain = true
axios.defaults.timeout = 8000

const request = (method, url, params, headerContentType = 'application/json') => {
  const headers = {
    'Content-Type': headerContentType
  }

  return new Promise((resolve) => {
    axios({
      method,
      headers,
      url,
      timeout: 15000,
      params: method === 'GET' || method === 'DELETE' ? params : null,
      data: method === 'POST' || method === 'PUT' ? params : null
    }).then(res => {
      Toast.clear()
      if (res.data.code === 0) {
        res.data.success = true
        resolve(res.data)
      } else {
        res.data.success = false
        Toast.fail(res.data.message, 5)
        resolve(res.data)
      }
    }).catch(error => {
      Toast.clear()
      let messageText = ''
      if (error.response && error.response.data && error.response.data.message) {
        messageText = error.response.data.message
      } else {
        messageText = 'Network exception'
      }
      Toast.fail(messageText, 5)
      resolve({ success: false, records: [], result: {}, total: 0, message: 'Network exception' })
    })
  })
}

export default request
