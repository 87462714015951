<template>
  <div id="app">
    <div class="form-wrap">
      <div class="form-title">注册资料</div>

      <div class="form-item">
        <div class="label">姓名</div>
        <input v-model="userInfo.name" type="text" placeholder="请输入姓名" />
      </div>

      <div class="form-item">
        <div class="label">年龄</div>
        <!-- <input v-model="userInfo.age" type="number" :min="1" :max="99" title="请输入一个1到99之间的整数" required placeholder="请输入年龄" /> -->
        <input v-model='userInfo.age' type="number" 
          oninput="if(!/^[1-9]+$/.test(value)) value=value.replace(/\D/g,'');if(value>100)value=100;if(value<0)value=null"
          min="1" max="100"
          required placeholder="请输入年龄" 
        />
      </div>

      <div class="form-item">
        <div class="label">性别</div>
        <van-radio-group v-model="userInfo.sex" class="r-group" direction="horizontal">
          <van-radio name="0">女</van-radio>
          <van-radio name="1">男</van-radio>
        </van-radio-group>
      </div>
      <van-button :loading="loading" :disabled="loading" class="btn" type="primary" @click="reigster" >匹配</van-button>
    </div>
    <!--弹窗-->
    <van-popup v-model="showDialog" class="win"
      :overlayStyle="{ background: 'rgba(123,101,128,0.7)' }" :style="{ width: '100%' }"
      round
      @click-overlay="closeDialog">
      <div class="dialog">
        <div class="dialog-title">匹配成功</div>
        <div class="dialog-content">{{ kakaoLink }}</div>
        <div class="dialog-btn">
          <van-button type="primary" @click="closeDialog" :url="kakaoLink" >点击添加好友</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import request from './utils/request';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
     loading: false,
     showDialog: false,
      userInfo: {
        name: '',
        age: '',
        sex: ''
      },
      kakaoLink: ''
    };
  },
  methods: {
    async reigster() {
      if (!this.userInfo.name) {
        Toast('请输入姓名');
        return;
      }
      if (!this.userInfo.age) {
        Toast('请输入年龄');
        return;
      }
      if (!this.userInfo.sex) {
        Toast('请选择性别');
        return;
      }

      console.log(this.userInfo);
      // 判断age是否为数字, 且在1-99之间, 且为整数
      if (!/^[1-9]\d*$/.test(this.userInfo.age)) {
        Toast('年龄只能1-99');
        return;
      }

      // 判断sex是否为"0"或"1"
      if (!["0", "1"].includes(this.userInfo.sex)) {
        Toast('请选择性别');
        return;
      }
      const params = {
        name: this.userInfo.name,
        age: this.userInfo.age,
        sex: this.userInfo.sex
      }
      this.loading = true;
      const response = await this.doSubmit(params);
      this.loading = false;
      if (response.success) {
        this.kakaoLink = response.data.links[0];
        this.showDialog = true;
      } else {
        Toast(response.msg);
      }
      
    },
    async doSubmit(params) {
      return request('POST', 'https://ry7akjnmfb.execute-api.ap-northeast-2.amazonaws.com/api/link/match', params)
    },
    closeDialog() {
      this.showDialog = false;
    }
  },
  mounted() {
    
    document.addEventListener('touchstart', function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    });

    let lastTouchEnd = 0;
    document.addEventListener('touchend', function (event) {
      let now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, false);

    document.addEventListener('gesturestart', function (event) {
      event.preventDefault();
    });

  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 20px;
}

.form-wrap {
  width: 95%;
  height: 100%;
  display: flex;
  color: burlywood;
  flex-direction: column;
  margin: auto;
  margin-top: 50px;

  .form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .form-item {
    display: flex;
    margin-bottom: 20px;
    height: 42px;
    font-size: 14px;
    background-color: #fff;
    .label {
      align-items: center;
      width: 35px;
      margin: auto;
      text-align: right;
      margin-right: 10px;
    }

    input {
      flex: 1;
      padding: 5px;
    }

    .r-group {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }

   
  }
  .btn {
    width: 240px;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 5px;
    margin-top: 30px;
    /deep/.van-button__text {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.win {
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog {
    width: 80%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    .dialog-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .dialog-content {
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }
    .dialog-btn {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      /deep/.van-button__text {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

/deep/.van-popup{
  width: 90% !important;
}
/deep/.van-button {
  border-radius: 6px !important;
}
</style>
